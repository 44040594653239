<template>
  <div class="project">
    <loader ref="loader" type="page"></loader>
    <div id="page-content" v-bind:class="[pageContentVisibilityState]">
      <div class="container">
        <div class="row text-center">
          <div class="col Nexa-Bold" style="padding-top: 30%; padding-bottom: 30%;" v-html="contactContent"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { gql } from '@/gql/views/contact.js'
import Api from '@/api.js'
import Loader from '@/components/Loader.vue'
import Seomatic from '@/seomatic.js'

export default {
  name: 'Contact',
  metaInfo () {
      return {
          title: this.metaData.title,
          meta: this.metaData.tags
      }
  },
  data () {
    return {
      contactContent: '',
      pageContentVisibilityState: '',
      metaData: {}
    }
  },
  components: {
    Loader
  },
  methods: {
    loadPage: function () {
      // Load the Page Function and Get Data
      const api = new Api({
        callback: (response) => {
          // Response Gotten
          if (response.data.entry) {
            var entry = response.data.entry
            console.log(entry)
            this.contactContent = entry.contactContent
            const seomatic = new Seomatic()
            this.metaData = seomatic.setMetaData(entry.seomatic)
          }
          this.$router.onReady(() => {
            this.$refs.loader.stopAnimation()
            this.pageContentVisibilityState = 'active'
          })
        },
        query: gql,
        variables: {
          preview: {
            token: this.$route.query.token
          }
        }
      })
      console.log(api)
    }
  },
  mounted () {
    this.$router.onReady(() => {
      this.$refs.loader.startAnimation()
      this.loadPage()
    })
  }
}
</script>
