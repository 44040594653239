export const gql = `query{
  entry(
    section:"contact"
  ){
    id
    title
    ... on contact_contact_Entry{
    	contactContent
    }
    seomatic(asArray: true){
      metaTitleContainer
      metaLinkContainer
      metaScriptContainer
      metaJsonLdContainer
      metaTagContainer
    }
  }
}`
